import {globalHistory} from '@reach/router';

export const onInitialClientRender = () => {
  /**
   * This is a workaround for a bug in Gatsby
   *
   * See https://github.com/gatsbyjs/gatsby/issues/8357 for more details
   */
  globalHistory._onTransitionComplete();
  // let date = new Date();
  // let timestamp = date.getTime();
  // console.log("onInitialClientRender " + timestamp)
}

// export const onClientEntry = () => {
//   let date = new Date();
//   let timestamp = date.getTime();
//   console.log("onClientEntry " + timestamp)
// }

// export const onRouteUpdate  = ({ location, prevLocation }) => {
//   console.log('new pathname', location.pathname)
//   console.log('old pathname', prevLocation ? prevLocation.pathname : null)
//   let date = new Date()
//   let timestamp = date.getTime()
//   const something = "onRouteUpdate " + timestamp
//   console.log(something)
// }
