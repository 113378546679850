// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-assets-index-js": () => import("./../../src/pages/assets/index.js" /* webpackChunkName: "component---src-pages-assets-index-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lb-covid-19-reviews-hidden-business-owner-js": () => import("./../../src/pages/lb/covid-19-reviews-hidden/business-owner.js" /* webpackChunkName: "component---src-pages-lb-covid-19-reviews-hidden-business-owner-js" */),
  "component---src-pages-lb-covid-19-reviews-hidden-index-js": () => import("./../../src/pages/lb/covid-19-reviews-hidden/index.js" /* webpackChunkName: "component---src-pages-lb-covid-19-reviews-hidden-index-js" */),
  "component---src-pages-test-formtest-js": () => import("./../../src/pages/test/formtest.js" /* webpackChunkName: "component---src-pages-test-formtest-js" */),
  "component---src-pages-test-index-js": () => import("./../../src/pages/test/index.js" /* webpackChunkName: "component---src-pages-test-index-js" */),
  "component---src-pages-test-page-2-js": () => import("./../../src/pages/test/page-2.js" /* webpackChunkName: "component---src-pages-test-page-2-js" */),
  "component---src-pages-test-step-1-js": () => import("./../../src/pages/test/step-1.js" /* webpackChunkName: "component---src-pages-test-step-1-js" */),
  "component---src-pages-test-step-3-js": () => import("./../../src/pages/test/step-3.js" /* webpackChunkName: "component---src-pages-test-step-3-js" */),
  "component---src-pages-test-step-4-js": () => import("./../../src/pages/test/step-4.js" /* webpackChunkName: "component---src-pages-test-step-4-js" */),
  "component---src-pages-test-step-5-js": () => import("./../../src/pages/test/step-5.js" /* webpackChunkName: "component---src-pages-test-step-5-js" */),
  "component---src-templates-review-script-pages-js": () => import("./../../src/templates/reviewScriptPages.js" /* webpackChunkName: "component---src-templates-review-script-pages-js" */),
  "component---src-templates-video-block-pages-js": () => import("./../../src/templates/videoBlockPages.js" /* webpackChunkName: "component---src-templates-video-block-pages-js" */)
}

